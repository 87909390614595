export default {
  convertCurrency(local, currency, value) {
    return new Intl.NumberFormat(local, { style: "currency", currency: currency }).format(value);
  },

  iOS() {
    return (
      ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }
};
